import React, {useContext} from "react"
import {PlantContext} from "./PlantContext"
import {Link, Switch, Route} from "react-router-dom"
import {BrowserRouter as Router} from "react-router-dom"
import Home from "./Home"
import MyList from "./MyList"
import PlantCard from "./PlantCard"

function App() {
  const {getPlants, navClosed, openNav, closeNav} = useContext(PlantContext)

  return (
    <Router>
      <div onClick={navClosed ? openNav : closeNav} id="navigation">
        <div className="hamburger"></div>
        <div className="hamburger"></div>
        <div className="hamburger"></div>
      </div>
      <div id="mySidenav" class="sidenav">
        <a href="https://www.tylergthomas.com">Home</a>
        <a href="https://artic.tylergthomas.com">ArtIC Digital Gallery</a>
        <a href="https://blog-props.tylergthomas.com">Blog Props</a>
        <a href="https://ugly-things.tylergthomas.com">Ugly Things Forum</a>
        <a href="https://tropicalfav.tylergthomas.com">Tropical Fruits and Veggies</a>
        <a href="https://meme-generator.tylergthomas.com">Meme Generator</a>
        <a href="https://name-badge.tylergthomas.com">Name Badge Creator</a>
      </div>
      <div className="navbar maxWidthContainer">
          <Link to="/" onClick={() => getPlants()} style={{ textDecoration: "none" }}>Home</Link>
      </div>
      <div>
          <Switch>
              <Route exact path="/"><Home /></Route>
              <Route exact path="/:plantID"><PlantCard /></Route>
          </Switch>
      </div>
    </Router>
  );
}

export default App;